.button__item {
  cursor: pointer;
}

.drop__menus {
  color: red;
  text-align: center;
}

.on__foccus:hover {
  font-weight: 500;
}

.link__button {
  color: blue;
  cursor: pointer;
  text-decoration: none;
}

.link__button:hover {
  text-decoration: underline;
  font-weight: 500;
}

.link__button__disabled {
  color: gray;
  cursor: not-allowed;
}

.link__button__disabled:hover {
  text-decoration: none;
}

.button__icon {
  cursor: pointer;
}

.despesas__table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.8em;
}

.despesas__table td,
.despesas__table th {
  border: 1.5px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.despesas__table th {
  background-color: #41839d;
  color: #fff;
  position: sticky;
}

.despesas__table__header__fixed {
  position: sticky;
  top: 0;
  z-index: 2;
}

.despesas__table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.despesas__table tr:nth-child(odd) {
  background-color: #ffffff;
}

.despesas__table tbody tr:hover {
  background-color: #eaecf4;
  font-weight: 500;
}

.despesas__table tr.disabled__line {
  background-color: #e4cece;
}

.despesas__table tr.alert__line {
  background-color: #fff8e3;
}

.despesas__table tr.disabled__line:hover {
  background-color: #c2c2c2b9;
}

.despesas__table .disabled__mouse:hover {
  cursor: not-allowed;
}

@media screen and (max-width: 600px) {
  .despesas__table {
    overflow-x: auto;
    display: block;
  }

  .despesas__table td,
  .despesas__table th {
    white-space: nowrap;
  }
}

.despesas__table .table__tooltip div {
  background-color: red;
}

.table__tooltip__header__error,
.table__tooltip__header__alert,
.table__tooltip__body {
  border: 1.5px solid black;
}

.table__tooltip__header__error {
  background-color: #eb2606;
  color: #f3ecd6;
}

.table__tooltip__header__alert {
  background-color: #5e1675;
  color: #ffd23f;
}

.table__tooltip__body {
  border-radius: 0px 0px 10px 10px;
  margin: 0px;
  padding: 5px 10px;
}

.value__number {
  text-align: end;
}

.value__date {
  text-align: center;
}

.order__button {
  text-decoration: underline;
  cursor: pointer;
}

.order__button:hover {
  font-weight: 500;
}

.not__display {
  display: none;
}
